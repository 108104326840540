import "bootstrap/dist/js/bootstrap.min";

import Vue from 'vue';
import App from './App.vue';

import routers from "./router";
const router = routers.get(process.env.VUE_APP_COMPANY);

Vue.config.productionTip = false;

import "../public/css/materialdesignicons.min.css";;
import 'swiper/swiper-bundle.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { BootstrapVue } from 'bootstrap-vue';
import VueScrollTo from "vue-scrollto";
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube';
import vueVimeoPlayer from 'vue-vimeo-player';
import VueMasonry from 'vue-masonry-css';
import VueMeta from "vue-meta";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { BootstrapVueIcons } from "bootstrap-vue";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import VueMask from 'v-mask'
Vue.use(VueMask);

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
 
Vue.use(VueMoment, {
    moment,
})

Vue.use(Antd);
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Scrollspy);
Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
Vue.use(VueMasonry);
Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease",
});
Vue.use(VueMeta, { refreshOnceOnNavigation: true });

let app;

import { auth, } from "@/services/firebase.service";
import { setColorTheme, setHeader, } from "./utils/frontend";
import store from "@/store";
import SmartLabel from "@/components/core/SmartLabel";
import Label from "@/components/core/Label";
import contentMixin from "@/mixins/mixinContent";

Vue.component("SmartLabel", SmartLabel);
Vue.component("Label", Label);
Vue.mixin(contentMixin);

(async () => {
  await store.dispatch("system/GET_CONFIGS");

  setColorTheme();
  setHeader(store.getters["system/header"]);
  
  for (const page of store.getters["system/pages"]) {
    for (const component of page.children) {
      const { name, folder } = component;
      Vue.component(name, () => import(`./components/${folder}/${name}`));
    }
  }

  auth.onAuthStateChanged(async user => {
    if (user) {
      await store.dispatch("auth/SIGN_IN", user.uid);
    }
  
    if (!app) {
      app = new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount("#app");
    }
  });

})();